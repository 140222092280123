import React from "react"
import VideoPage from "../../components/VideoPage"

const data = [
  {
    name: "COME Block 4A",
    link: "https://www.youtube.com/embed/Kp0zmgKTB5k",
  },
  {
    name: "COME Block 4B",
    link: "https://www.youtube.com/embed/VE_UVjwC_mc",
  },
  {
    name: "COME Block 6A",
    link: "https://www.youtube.com/embed/Fp6_e_gmLEk",
  },
  {
    name: "COME Block 6B",
    link: "https://www.youtube.com/embed/W-enjqk5VIE",
  },
  {
    name: "COME Block 7A",
    link: "https://www.youtube.com/embed/p3Pfhzs8ZZI",
  },
  {
    name: "COME Block 8B",
    link: "https://www.youtube.com/embed/P4sSWQbOuvQ",
  },
]

const ComePage = () => <VideoPage videos={data} title="COME" />

export default ComePage
